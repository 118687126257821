<template>
  <div>
    <div
      :class="desc === '秒后重发' ? 'sms-blue' : 'sms-blue-bold'"
      v-if="codeShow"
      @click="smsCodeClick"
    >
      获取验证码
    </div>
    <div class="flex flex-align-center" v-if="!codeShow">
      <p :class="desc === '秒后重发' ? 'sms-blue' : 'sms-blue-bold'">
        {{ count }}
      </p>
      <div :class="{ space: space }"></div>
      <p :class="desc === '秒后重发' ? 'sms-blue' : 'sms-gray'">{{ desc }}</p>
    </div>
  </div>
</template>

<script>
import { phonePattern } from '@/utils/validate'

export default {
  name: "Smsbutton",
  data() {
    return {
      // 验证码倒计时
      codeShow: true,
      count: "",
      timer: null,
    };
  },
  props: {
    time: {
      type: Number,
      default: 60,
    },
    desc: {
      type: String,
      default: "秒后重发",
    },
    space: {
      type: Boolean,
      default: false,
    },
    // 验证码类型 100手机登录
    smsType: {
      type: Number,
      default: 1,
    },
    phone: {
      type: String,
      default: '',
    },
    getCode: {
      type: Function,
      default: () => { }
    },
    packageName:{
      type: String,
      default: '',
    }
  },
  created() {
    if (this.smsType == 300) {
      this.smsCodeClick()
    }
  },
  methods: {
    async smsCodeClick() {
      if (!phonePattern.test(this.phone)) {
        this.$toast('请输入正确的手机号');
        return;
      }

      const params = {
        phone: this.phone,
        type: 100,
        packageName:this.packageName
      }
      try {
        const res = await this.$api.user.login_send_captcha(params);
        const { code } = res.data.data;
        this.getCode(code);
        this.$toast('验证码获取成功');
        this.countDown();
      } catch (err) {
        console.log('%c 🍤 err: ', 'font-size:20px;background-color: #93C0A4;color:#fff;', err);
      }

    },

    //倒计时60秒
    countDown() {
      if (this.time !== 60) return;
      if (!this.timer) {
        this.codeShow = false;
        this.count = this.time;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= this.time) {
            this.count--;
          } else {
            this.codeShow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    }

  },
};
</script>

<style lang="scss" scoped>
.sms-blue {
  font-size: 14px;
  color: #2e5bfd;
}

.sms-blue-bold {
  font-size: 12px;
  font-weight: 500;
  color: #3d7bd8;
}

.space {
  padding-left: 4px;
}

.sms-gray {
  font-size: 12px;
  font-weight: 500;
  color: #93949d;
}
</style>