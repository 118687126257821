import Vue from 'vue'
import Router from 'vue-router'
const Register = (resolve) => require(['@/views/login/register.vue'], resolve)
Vue.use(Router)
// 按需加载,当渲染其他页面时才加载其组件,并缓存,减少首屏加载时间

const router = new Router({
	routes: [
		{
			path: '/',
			redirect: '/reg/register',
		},
		{
			path: '/reg',
			name: '注册',
			redirect: '/reg/register',
			component: {
				render() {
					return <router-view />
				},
			},
			children: [
				{
					path: 'register',
					name: 'register',
					component: () => import('@/views/login/register'),
				},
				{
					path: 'userAgreement',
					name: 'userAgreement',
					component: () => import('@/views/login/userAgreement'),
				},
				{
					path: 'secrecyAgreement',
					name: 'secrecyAgreement',
					component: () => import('@/views/login/secrecyAgreement'),
				},
			],
		},
		{
			path: '/increase',
			name: '增值',
			redirect: '/increase/index',
			component: {
				render() {
					return <router-view />
				},
			},
			children: [
				{
					path: 'index',
					name: 'increase',
					component: () => import('@/views/increase'),
					meta: {
						title: '申请信息核实',
					},
				},
				{
					path: 'test',
					name: 'test',
					component: () => import('@/views/increase/test'),
					meta: {
						title: '办卡',
					},
				},
				{
					path: 'card',
					name: 'card',
					component: () => import('@/views/increase/card'),
					meta: {
						title: '办卡',
					},
				},
				{
					path: 'cardList',
					name: 'cardList',
					component: () => import('@/views/increase/cardList'),
					meta: {
						title: '卡片列表',
					},
				},

				{
					path: 'loan',
					name: 'loan',
					component: () => import('@/views/increase/loan'),
					meta: {
						title: '贷款',
					},
				},
				{
					path: 'loanDetail',
					name: 'loanDetail',
					component: () => import('@/views/increase/loanDetail'),
					meta: {
						title: '贷款详情',
					},
				},
				{
					path: 'mobileCardList',
					name: 'mobileCardList',
					component: () => import('@/views/increase/mobileCardList'),
					meta: {
						title: '手机卡列表',
					},
				},
				{
					path: 'mobileCard',
					name: 'mobileCard',
					component: () => import('@/views/increase/mobileCard'),
					meta: {
						title: '手机卡详情',
					},
				},
				{
					path: 'mobileShareDetail',
					name: 'mobileShareDetail',
					component: () => import('@/views/increase/mobileShareDetail'),
					meta: {
						title: '手机卡详情',
					},
				},
				{
					path: 'succeed',
					name: 'succeed',
					component: () => import('@/views/increase/succeed'),
					meta: {
						title: '完成订单',
					},
				},

				{
					path: 'loanApply',
					name: 'loanApply',
					component: () => import('@/views/increase/loanApply'),
					meta: {
						title: '申请信息核实',
					},
				},
				{
					path: '/adPage',
					name: '内置H5',
					redirect: '/adPage/bocom',
					component: {
						render() {
							return <router-view />
						},
					},
					children: [
						{
							path: 'bocom',
							name: 'bocom',
							component: () => import('@/views/adPage/bocom'),
							meta: {
								title: '交通银行',
							},
						},
					],
				},
			],
		},
	],
	scrollBehavior() {
		// scrollBehavior(to, from, savedPosition) {
		// console.log('跳转数据',to, from, savedPosition);
		return {
			x: 0,
			y: 0,
		}
	},
})

router.beforeEach((to, from, next) => {
	const title = to.meta.title

	if (title) {
		document.title = title
	}

	next()
})

export default router
